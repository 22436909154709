import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class BlockCalendar extends Component {
  
     state={number: '', id : '', remarks: '', titles:[], appointmentTimes: [], showTimes: true, startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), endDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), companyId: localStorage.getItem('companyId'), consultantId: localStorage.getItem('userId'), showConsultants: false, consultants: [], showSubmit: false, selectAll: false}
    open()
    {    
     document.getElementById('blockCalendarViewAction').style = 'display:block' 
     axios.get(localStorage.getItem('host') + '/master/appointmenttimeactive/' + localStorage.getItem('companyId'), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ appointmentTimes: res.data});
            this.props.setLoader(false)
        }, err => {
            this.setState({ error: err + "" })
            this.props.setLoader(false)
        }
    )    
     
    }  
  
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)
            var data = {
                remarks: this.state.remarks, companyId: this.state.companyId, startDate: this.state.startDate, consultantId: this.state.consultantId, appointmentTimes: this.state.appointmentTimes
            };
            axios.post(localStorage.getItem('host') + '/patient/saveappointmentblock', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    if (res.data.error) {
                        this.props.setLoader(false)
                        this.setState({ error: res.data.error })
                        window.scrollTo(0, 0);
                    }
                    else {
                        this.setState({ msg: res.data.msg, showSubmit: false })
                        this.props.setLoader(false)
                        window.scrollTo(0, 0);
                    }
                }, err => {
                    this.setState({ error: err + "" })
                    this.props.setLoader(false)
                }
            )
        }
        else {
            this.setState({ error: error })
        }

    }
    closePopup() {
        document.getElementById('blockCalendarViewAction').style = 'display:none'
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
       return date1;
      }
      setItem(value, index)
      {
        var list = this.state.appointmentTimes.slice();
        list[index].selected = value
        var validate = this.doValdate(list)
        this.setState({appointmentTimes: list, showSubmit: validate, error: (validate?"":"Please select at least one time slot to block")})
      }  
      setCheckStatus(checked)
       {
        var list = this.state.appointmentTimes.slice();
        for(var a in list)
           {
            list[a].selected = checked === true ? "1" : "0"
           }
           var validate = this.doValdate(list)
           this.setState({appointmentTimes: list, selectAll: checked, showSubmit: validate, error: (validate?"":"Please select at least one time slot to block")})   
       }
       doValdate(list)
         {
            var t = 0;
            for(var a in list)
            {
                if(list[a].selected === "1")
                {
                  t++;  
                }
            }
            if(t === 0)
               {
                window.scrollTo(0, 0);
               }
            return t > 0;
         }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="blockCalendarViewAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Block Calendar Action</div>
            <div className="card-body" >
                {divMsg}
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} minDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                             <div className="col"> <div className="input-group"><span className="input-group-text">Reason</span>
                                                            <textarea className="form-control" value={this.state.remarks} onChange={e => { this.setState({ remarks: e.target.value }) }} ></textarea></div> </div>
                                             </div>
                       <table className="table table-striped">
                                                <tr>
                                                    <th><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.selectAll} onChange={e => this.setCheckStatus(e.target.checked)} /></th>
                                                    <th>Name</th>
                                                </tr>
                                                <tbody>
                                                {this.state.appointmentTimes.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={item.selected === "1"} onChange={e => this.setItem(e.target.checked === true ? "1" : "0" , index)} /></td>
                                                        <td>{item.name}</td>
                                                    </tr>))}
                                                    </tbody>
                                            </table> 
                <div className="row">
                <div className="col" align="center">{this.state.showSubmit === true && <button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Save</button>}</div>
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    