import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Product extends Component {
    state = {loader: false, showSubmit: false, id: 0, code: '', name: '', category: '', locationId: '', type: '', barcode: '', activeStatus: 1, productTypes: [ "ACCESSORIES", "ANAESTHETIC_DRUG", "CONSUMABLES", "DRUG", "LENSES", "FRAMES",  "OTC","STATIONERY","SUNDRIES", "OTHER"], categorys: [], locations: [], manufacturers: [], list: [], productClasss: [], suppliers: [], productItemLocations: [], productItemReorderBulks: [] }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    add() {
        let list = this.state.productItemLocations.slice();
        list.push({ id: 0, locationId: '', productItemId: '', quantity: 0, bookStock: 0 });
        this.setState({ productItemLocations: list })
    }
    setRowValue(index, e, name) {
        let list = this.state.productItemLocations.slice();
        switch (name) {
            case "locationId":
                list[index].locationId = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ productItemLocations: list })
    }
    showForm(id) {
        this.setState({ showSubmit: false, id: 0, code: '', name: '', category: '', locationId: '', type: '', barcode: '', activeStatus: 1, productTypes: [ "ACCESSORIES", "ANAESTHETIC_DRUG", "CONSUMABLES", "DRUG", "LENSES", "FRAMES",  "OTC","STATIONERY","SUNDRIES"], categorys: [], locations: [], productItems: [] , productItemLocations: []})
        this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/productaction/' + id + '/' + localStorage.getItem('companyId'), {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, category: res.data.category, code: res.data.code, locations: res.data.locations, categorys: res.data.categorys , type: res.data.type, barcode: res.data.barcode, activeStatus: res.data.activeStatus, productItemLocations: res.data.productItemLocations });
                    this.setLoader(false)
                    document.querySelector('#action').style = 'display:block'
                }, err => {
                    console.log(".....")
                }
            )
        
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
    }
    save() {
        this.setLoader(true)
        var data = { id: this.state.id, name: this.state.name, code: this.state.code, category: this.state.category, productItemLocations: this.state.productItemLocations, type: this.state.type, barcode: this.state.barcode, activeStatus: this.state.activeStatus, companyId: localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/saveproduct', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                this.setLoader(false)
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
   
    processDate(date) {
        if (date === "")
            return "";
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    sortDate(date) {
        if (date === "" || date === null)
            return "";
        return new Date(date);
    }
    getList() {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/master/products/' + localStorage.getItem('companyId') , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
            }
        )
    }
    showReorderBulkQuantity()
      {
        axios.get(localStorage.getItem('host') + '/master/getreorderlevelbulkquantity/' + localStorage.getItem('companyId') , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ productItemReorderBulks: res.data });
                this.setLoader(false)
                document.querySelector('#reorderlevelbulkquantity').style = 'display:block'
            }, err => {
                console.log(".....")
            }
        )

      }
      closePopupReorderBulkQuantity() {
          document.querySelector('#reorderlevelbulkquantity').style = 'display:none'
      }
      savePopupReorderBulkQuantity() {
          this.setLoader(true)
          axios.post(localStorage.getItem('host') + '/master/savereorderlevelbulkquantity', this.state.productItemReorderBulks, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          }).then(
              res => {
                  console.log(res.data)
                  this.setState({ msg: res.data });
                  this.closePopupReorderBulkQuantity();
                  this.setLoader(false)
                  setTimeout(() => {
                      this.setState({ msg: '' });
                  }, 3000);
              }, err => {
                  console.log(".....")
              }
          )
      }

    setRowValue1(index, e, name) {
        let list = this.state.productItemReorderBulks.slice();
        switch (name) {
            case "reOrderLevel":
                list[index].reOrderLevel = e.target.value;
                break;
            default:
                list[index].bulkQuantity = e.target.value;
                break;
        }
        this.setState({ productItemReorderBulks: list })
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    render() {
        const data = {
            columns: [

                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Code',
                    field: 'code',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Category',
                    field: 'category',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Type',
                    field: 'type',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Barcode',
                    field: 'barcode',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Active Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
           
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col"><button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button></div>
                            <div className="col"> <button className="btn btn-primary" onClick={() => this.showReorderBulkQuantity()} >Reorder / Bulk Quantity</button></div>
                        </div>
                        
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Product Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Name</span>
                                                    <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Category</span>
                                                    <select className="form-control" value={this.state.category} onChange={e => this.setState({ category: e.target.value })}  >
                                                        <option value="">Choose Category</option>
                                                        {this.state.categorys.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                    </select></div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Code</span>
                                                    <input type="text" className="form-control" value={this.state.code} onChange={e => { this.setState({ code: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Type</span>
                                                <select className="form-control" value={this.state.type} onChange={e => { this.setState({ type: e.target.value }) }}  >
                                                            <option value="0">Choose Product Type</option>
                                                        {this.state.productTypes.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                            </select></div>
                                            </div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">BuyingPrice</span>
                                                    <input type="text" className="form-control" value={this.state.buyingPrice} onChange={e => { this.setState({ buyingPrice: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select>
                                                   </div>
                                            </div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                <div className="input-group"><span className="input-group-text">Barcode</span>
                                                    <input type="text" className="form-control" value={this.state.barcode} onChange={e => { this.setState({ barcode: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"></div>
                                        </div>
                                        <div className="card card-green">
                                            <div className="card-header">Product Location</div>
                                            <div className="card-body">
                                                <table className="table table-striped"  >
                                                    <tr>
                                                        <th>Location</th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Menu" onClick={() => { this.add() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                <tbody>
                                                    {this.state.productItemLocations.map((item, index) => (
                                                        <tr key={index}>
                                                            <td><select className="form-control" value={item.locationId} onChange={e => {this.setRowValue(index, e, "locationId") }}  >
                                                                <option value="0">Choose Location</option>
                                                                {this.state.locations.map((l, index) => (
                                                                    <option key={index} value={l.id} >{l.name}</option>
                                                                ))}
                                                            </select></td>
                                                        </tr>
                                                    ))} </tbody>
                                                </table>
                                            </div>
                                        </div>

                                       
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                        <div className="page-popup page-popup-overlay" id="reorderlevelbulkquantity">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Product Re-Order Level / Bulk Quantity Action
                                    </div>
                                    <div className="card-body">
                                     <div className="tableMain">
                                                <table className="table table-striped table-container"  >
                                                  <thead className="table-header">
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Type</th>
                                                        <th>Category</th>
                                                        <th>Re-Order Level</th>
                                                        <th>Bulk Quantity</th>
                                                    </tr>
                                                    </thead>  
                                                <tbody className="table-tbody">
                                                    {this.state.productItemReorderBulks.map((item, index) => (
                                                        <tr className="table-tbody-tr" key={index}>
                                                            <td>{item.code}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.category}</td>
                                                            <td><input type="number" className="form-control" value={item.reOrderLevel} onChange={e => {this.setRowValue1(index, e, "reOrderLevel") }}  /></td>
                                                            <td><input type="number" className="form-control" value={item.bulkQuantity} onChange={e => {this.setRowValue1(index, e, "bulkQuantity") }}  /></td>
                                                        </tr>
                                                    ))} </tbody>
                                                </table>

                                                </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.savePopupReorderBulkQuantity()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopupReorderBulkQuantity()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                        {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    