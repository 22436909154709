import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Gateways extends Component {
    state = { list: [], id: 0, name: '', activeStatus: 1, msg: '', error: '' }
    componentDidMount() {
        this.getList()
       
    }
    GatewayAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({id: 0, name: '', activeStatus: 1, msg: ''})   
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/auth/gatewayaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#GatewayAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#GatewayAction').style = 'display:none'
        console.log('......close');
    }
    saveGateway() {
        var data = { id: this.state.id, name: this.state.name, activeStatus: this.state.activeStatus }
        axios.post(localStorage.getItem('host') + '/auth/gatewayform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/auth/gateway', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Gateway" id={res.data[index].id} onClick={e => this.GatewayAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />

                <div className="container-fluid">
                <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add Gateway" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="GatewayAction">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Gateway Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select></div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveGateway()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div></div>
        )

    }
}    