import React, { Component } from 'react'
import Top from '../includes/top'
import SideBar from '../includes/sidebar'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import axios from "axios";
import { Chart, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Chart.register(...registerables);

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Dashboard extends Component {
    state = {
        admin: localStorage.getItem('admin') === "1", type: 'DEFAULT', loggedInUsers: [], expiryDate: date1, notificationDate:date1, licenceStatus: '', tradingCurrencyId: 0, tradingCode: '', baseCode: '', baseRate: '', newRate: '', rateError: '', showRate: false, tradingRate: '', link: '', softwareKey: 1000001, visitCount: {
            labels: [],
            datasets: [],
        }, categoryCount: {
            labels: [],
            datasets: [],
        }
        , heapSize: 0, heapMaxSize: 0, heapFreeSize: 0, loginattempts: [], companys: [], loader:false, error: '', companyId: 0, message: '', messageType: 'INFO',
        stockLocationValues: [], reoderLevels: [], stockSales: { labels: [], datasets: []}, smsDaily:  { labels: [], datasets: []},smsCountDaily:  { labels: [], datasets: []},  smsCountDailyCumulative: { labels: [], datasets: []}, receiptCount:  { labels: [], datasets: []}, ageWiseCount:  { labels: [], datasets: []}, gateways: [], nh263host: '', msgNh263: '', errorNh263: ''
    }
    componentDidMount() {
        //  this.getOrgStructure()
         this.getDashboard()

    }
    getDashboard() {
        axios.get(localStorage.getItem('host') + '/dashboard/getdashboard/' + localStorage.getItem('companyId')+'/' + localStorage.getItem('roleId') , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({type: res.data.type, licenceStatus: res.data.licence, tradingCurrencyId: res.data.tradingCurrencyId, tradingCode: res.data.tradingCode, tradingRate: res.data.tradingRate, baseCode: res.data.baseCode, baseRate: res.data.baseRate, visitCount: res.data.visitCount, categoryCount: res.data.categoryCount
                , heapSize: res.data.heapSize, heapMaxSize: res.data.heapMaxSize, heapFreeSize: res.data.heapFreeSize, loggedInUsers: res.data.activeUsers, loginattempts: res.data.loginAttempts, companys: res.data.companys , smsDaily: res.data.smsDaily, smsCountDaily: res.data.smsCountDaily, smsCountDailyCumulative: res.data.smsCountDailyCumulative, receiptCount: res.data.receiptCount, ageWiseCount: res.data.ageWiseCount,
                stockLocationValues: res.data.stockLocationValues, reoderLevels: res.data.reoderLevels, stockSales:res.data.stockSales, gateways: res.data.gateways});
            }, err => {
                console.log(".....")
            }
        )
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
        

      }
    generateLicence()  
    {
        var error = "";    
        if (this.state.expiryDate === "")
        {
            error = "Expiry Date cannot be empty";
        }
        if (this.state.softwareKey === "" && error === "")
        {
            error = "Software Key cannot be empty";
        }
        if (error === "")
        {
            this.setLoader(true);
            axios.get(localStorage.getItem('host') +"/auth/generatelicence/" + this.state.softwareKey + "/" + this.state.expiryDate, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {

                this.setLoader(false);
                this.setState({licenceKey: res.data})
            })
        } else
        {
            this.setState({error:error})
        }    
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openRateAction(){
        this.setState({newRate: this.state.rate, rateError: '', showRate: false}) 
        document.getElementById('rateAction').style = 'display:block'
    }
    closeRate(){
        document.getElementById('rateAction').style = 'display:none'
    }
    doValidate(rate)
      {
        var error = false;
        if (rate === null) {
            this.setState({ rateError: "Rate cannot be empty" });
            error = true
        }
        else if (rate === "") {
            this.setState({ rateError: "Rate cannot be empty" });
            error = true
        }
        else if (rate === 0) {
            this.setState({ rateError: "Rate cannot be zero" });
            error = true
        }
        else if (rate < 0) {
            this.setState({ rateError: "Rate should be a value greater than zero" });
            error = true
        }
        else {
            this.setState({ rateError: "" })
        }
      
        this.setState({ newRate: rate, showRate: !error })
      }
      updateRate()
      {
        this.setLoader(true)
        var data = {id: this.state.tradingCurrencyId, rate: this.state.newRate}
        axios.post(localStorage.getItem('host') + '/master/currencyupdate', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                this.setState({ msg: res.data, tradingRate: this.state.newRate });  
                this.closeRate()
                window.scrollTo(0, 0)
            }, err => {
                 this.setState({error: err+""})
                 window.scrollTo(0, 0)
                this.setLoader(false)
            }
        )   
      }   
    saveNotifications()
                      {
                         var error = "";
                        if (error === "")
                        {
                            this.setLoader(true);
                            
                            axios.post(localStorage.getItem('host') +"/auth/savenotifications", {message: this.state.message, messageType: this.state.messageType, messageStatus: 'CREATED', companyId: this.state.companyId, notificationDate: this.state.notificationDate}, {
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                }
                            })
                                    .then(res => {
                                        this.setState({msg: res.data})
                                        window.scrollTo(0, 0);
                                        this.setLoader(false);
                                    })     
                        }     
                      }
    updateGateway()
           {
            this.setLoader(true)
        axios.post(localStorage.getItem('host') + '/auth/gatewayupdate', this.state.nh263host, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                this.setState({ msgNh263: res.data, errorNh263:'' });  
            }, err => {
                 this.setState({errorNh263: err+"", msgNh263: ''})
                this.setLoader(false)
            }
        )   
           }
    checkGateway()
           {
            this.setLoader(true);
            axios.get(this.state.nh263host +"/ucarenh263/interchange/ping").then((res) => {

                this.setLoader(false);
                this.setState({msgNh263: 'Successfully connected to gateway', errorNh263:''})
            }, err => {
                this.setState({errorNh263: err+"", msgNh263: ''})
               this.setLoader(false)
           })
           }                        
    render() {      
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        let divH263Msg = ''
        if (this.state.msgNh263) {
            divH263Msg = (<div className='alert alert-success'>{this.state.msgNh263}</div>)
        }
        if (this.state.errorNh263) {
            divH263Msg = (<div className='alert alert-danger'>{this.state.errorNh263}</div>)
        }
        return (
            <div className='d-flex' id='wrapper'>
                <SideBar />
                <div id='page-content-wrapper'>
                    <Top />
                    <div className="container-fluid">
                        <br />
                        <h2>Welcome To Ucare</h2>
                        {divMsg}
                        <div className="page-popup page-popup-overlay" id="rateAction" >
                                            <div className="card" >
                                            <div className="card-header  bg-info">Rate Action</div>
                                            <div className="card-body"  >
                                                        <div id="doctorMsg"></div>
                                                        <div className="row">
                                                            <div className="col">
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">Rate</span><input type="text" className={this.state.rateError !== "" ? "form-control is-invalid" : "form-control"} autoComplete="no" onInput={e => { this.doValidate(e.target.value) }} value={this.state.newRate} />
                                                                <div className="invalid-feedback">{this.state.rateError}</div>
                                                            </div></div>
                                                            <div className="col"></div>
                                                            <div className="col"></div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col" align="center">{ this.state.showRate === true && (<input type="button" className="btn btn-primary" onClick={() => this.updateRate()} value="Save" />)}</div>
                                                            <div className="col" align="center"> <input type="button" className="btn btn-primary" onClick={() => this.closeRate()} value="Close" /></div>
                                                        </div></div></div>
                                            </div>
                        <div className="row">
                            <div className="col-lg-6"><div className="fw-bold">Licence:</div> <div dangerouslySetInnerHTML={{ __html: this.state.licenceStatus }}></div></div>
                            <div className="col-lg-6"><span className="fw-bold">Trading as {this.state.tradingCode}:</span> {this.state.baseCode} {this.state.baseRate} : {this.state.tradingCode} {this.state.tradingRate} { this.state.tradingCurrencyId > 0 && <span  className="link" onClick={() => this.openRateAction()} title="Change Rate"><i className="bi bi-pencil-square"></i></span>}</div>
                        </div>
                        { this.state.type === 'DEFAULT' && 
                        <div ><div className="row">
                            <div className="col-md-6">
                                <div className="card card-green">
                                    <div className="card-header">Patients</div>
                                    <div className="card-body">
                                        <Bar
                                            datasetIdKey='Patients'
                                            options={{
                                              responsive: true,
                                              maintainAspectRatio: false,
                                            }}
                                            height={400}
                                            data={this.state.visitCount}
                                        />

                                    </div></div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-green">
                                    <div className="card-header">Category</div>
                                    <div className="card-body">
                                        <Pie
                                            datasetIdKey='Category'
                                            options={{
                                              responsive: true,
                                              maintainAspectRatio: false,
                                            }}
                                            height={400}
                                            data={this.state.categoryCount}
                                        />

                                    </div></div>
                            </div>
                        </div>
                            <div className="row">
                                <div className="col-lg-12">
                                <Bar datasetIdKey='receiptCount'
                                options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                }}
                                height={500}
                                 data={this.state.receiptCount} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                <Bar datasetIdKey='ageWiseCount'
                                options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                }}
                                height={500}
                                 data={this.state.ageWiseCount} />
                                </div>
                            </div>
                            </div> }
                            { this.state.type === 'STOCK' &&
                             <div>
                                <div className='row'>
                                 <div className="col">
                                    <h4>Locations</h4>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Received</th>
                                                <th>Used / Issued</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.stockLocationValues.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.receivedQuantity}</td>
                                                    <td>{item.usedQuantity}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                 <div className="col">
                                    <h4>Re-Order Level</h4>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Re-Order Level</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reoderLevels.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.reorderLevel}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                    <div className="row">
                            <div className="col-md-6">
                                <div className="card card-green">
                                    <div className="card-header">Sales</div>
                                    <div className="card-body">
                                        <Bar
                                            datasetIdKey='Patients'
                                            options={{
                                              responsive: true,
                                              maintainAspectRatio: false,
                                            }}
                                            height={400}
                                            data={this.state.stockSales}
                                        />

                                    </div></div>
                            </div>
                              <div className='col'></div>
                              </div>
                             </div>   
                            }
                        <br />
                        {this.state.admin === true ? (<div >
                            <div className="card border-info">
                                <div className="card-body">
                                    {divMsg}
                                    <div className="row">
                                        <div className="col-lg-6"><div className="input-group"><span className="input-group-text">Date</span><DatePicker className="form-control" selected={new Date(this.state.expiryDate)} onChange={(date) => { this.setState({ expiryDate: this.processDate(date) });  }} dateFormat="yyyy-MM-dd" /> </div></div>
                                        <div className="col-lg-6"><div className="input-group"><span className="input-group-text">Software Key</span><select className="form-control" value={this.state.softwareKey} onChange={e => this.setState({ softwareKey: e.target.value })} >
                                            <option value="1000001">1000001</option>
                                            <option value="1000002">1000002</option>
                                            <option value="1000003">1000003</option>
                                            <option value="1000004">1000004</option>
                                            <option value="1000005">1000005</option>
                                            <option value="1000006">1000006</option>
                                            <option value="1000007">1000007</option>
                                            <option value="1000008">1000008</option>
                                            <option value="1000009">1000009</option>
                                        </select></div></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"><input type="button" value="Generate Licence" className="btn btn-primary" onClick={() => this.generateLicence()} /></div>
                                        <div className="col-lg-6">{this.state.licenceKey}</div>
                                    </div>
                                </div></div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Pie datasetIdKey='smsCountDaily'
                                        options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        }} 
                                        height= {400}
                                        data={this.state.smsCountDaily} />
                                </div>
                                <div className="col-lg-6">
                                    <Pie datasetIdKey='smsCountDailyCumulative'
                                        height= {400}
                                        options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        }} 
                                        data={this.state.smsCountDailyCumulative} />
                                                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                <Bar datasetIdKey='smsDaily'
                                options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                }}
                                height={500}
                                 data={this.state.smsDaily} />
                                </div>
                            </div>

                            <div className="card">
                                  <div className="card-header bg-info">Health 263 Gateway</div>
                                    <div className="card-body">
                                       <div className="row">
                                           <div className="col"><select className="form-control" value={this.state.nh263host} onChange={e => { this.setState({ nh263host: e.target.value }) }}  >
                                                <option value="">Choose</option>
                                                {this.state.gateways.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                </select></div>
                                           <div className="col"><button className="btn btn-success"  onClick={() => this.updateGateway()}>Update</button></div>
                                           <div className="col"><button className="btn btn-success"  onClick={() => this.checkGateway()}>Check Connection</button></div>
                                          </div>
                                          <div className="row">
                                              <div className="col">{divH263Msg}</div>
                                            </div>
                                        </div>
                                </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4>Memory Status</h4>
                                    <table className="table table-striped">
                                        <tr>
                                            <th>Heap Size</th>
                                            <th>Heap Max Size</th>
                                            <th>Heap Free Size</th>
                                        </tr>
                                        <tbody>
                                        <tr>
                                            <td>{this.state.heapSize}GB</td>
                                            <td>{this.state.heapMaxSize}GB</td>
                                            <td>{this.state.heapFreeSize}GB</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-12">
                                    <h4>Logged In Users</h4>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Username</th>
                                                <th>Login Date</th>
                                                <th>Update Date</th>
                                                <th>IP Address</th>
                                                <th>Country</th>
                                                <th>ISP</th>
                                                <th>Browser</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loggedInUsers.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.username}</td>
                                                    <td>{item.loginDateTime}</td>
                                                    <td>{item.lastUpdateDateTime}</td>
                                                    <td>{item.remoteIp}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.isp}</td>
                                                    <td>{item.browser}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12"> <h4>Failed Attempts</h4>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Username</th>
                                                <th>Failed Logins</th>
                                                <th>IP Address</th>
                                                <th>Country</th>
                                                <th>ISP</th>
                                                <th>Browser</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loginattempts.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.username}</td>
                                                    <td>{item.loginDateTime}</td>
                                                    <td>{item.remoteIp}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.isp}</td>
                                                    <td>{item.browser}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table></div>
                                <div className="col-lg-6"></div>
                            </div>
                            <div className="card border-info">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4"><div className="input-group"><span className="input-group-text">Message</span><textarea className="form-control" value={this.state.message} onChange={e => this.setState({ message: e.target.value })}></textarea></div></div>
                                        <div className="col-lg-4"><div className="input-group"><span className="input-group-text">Date</span><DatePicker className="form-control" selected={new Date(this.state.notificationDate)} onChange={(date) => { this.setState({ notificationDate: this.processDate(date) }); }} dateFormat="yyyy-MM-dd" /></div></div>
                                        <div className="col-lg-4"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={e => this.setState({ companyId: e.target.value })} >
                                            {this.state.companys.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </select></div></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4"><div className="input-group"><span className="input-group-text">Type</span><select className="form-control" value={this.state.messageType} onChange={e => this.setState({ messageType: e.target.value })} >
                                            <option value="ERROR">ERROR</option>
                                            <option value="WARNING">WARNING</option>
                                            <option value="INFO">INFO</option>
                                        </select></div></div>
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-4"><input type="button" className="btn btn-info" onClick={() => this.saveNotifications()} value="Save" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>) : (<div></div>)}
                        {loaderDiv}
                    </div>

                </div>
            </div>
        )

    }
}